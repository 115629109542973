<template>
  <div
    class="grid mt-3 surface-card shadow-2 p-3 border-1 border-50 border-round"
  >
    <div class="col-12"><h3>Mailings</h3></div>

    <DataTable
      class="col-12"
      :value="formattedMailings"
      responsiveLayout="scroll"
      :loading="loading"
      :lazy="true"
      showGridlines
      :paginator="true"
      :rows-per-page-options="rowSelectionOptions"
      v-model:rows="rowsToShow"
      :totalRecords="totalRecords"
      @sort="onSort($event)"
      @page="onPage($event)"
    >
      <!-- <Column field="name" header="Job#" :sortable="true"></Column> -->
      <Column
        field="description"
        header="Description"
        :sortable="true"
      ></Column>

      <Column
        v-for="column in selectedColumns"
        :key="column.field"
        :field="column.field"
        :header="column.header"
        :sortable="column.sortable"
        style="text-align: right"
      ></Column>

      <Column header="" style="text-align: right">
        <template #body="slotProps">
          <Button
            label="View Mailing"
            @click="
              $router.push({
                name: 'mailing-overview',
                params: { id: slotProps.data.id },
              })
            "
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import COMailing from "@/models/CampaignOverviewMailing";
import QueryBuilderService from "@/service/QueryBuilderService";
import numeral from "numeral";
import { useAppStore } from "@/stores/app";
export default {
  name: "MailingTable",
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  data() {
    return {
      mailings: [],
      formattedMailings: [],
      allColumns: [
        {
          key: "contacts",
          header: "Contacts",
          sortable: false,
          field: "contacts",
          toggle: true,
          numberFormat: true,
        },
        {
          key: "total_leads",
          header: "Total Leads",
          sortable: false,
          field: "totalLeads",
          toggle: true,
          numberFormat: true,
        },
        {
          key: "set_counter",
          header: "Set",
          sortable: false,
          field: "setCounter",
          toggle: true,
        },
        {
          key: "issued_counter",
          header: "Issued",
          sortable: false,
          field: "issuedCounter",
          toggle: true,
        },
        {
          key: "sale_counter",
          header: "Sales",
          sortable: false,
          field: "saleCounter",
          toggle: true,
        },
        {
          key: "total_sales_amount",
          header: "Total Sales",
          sortable: false,
          field: "totalSalesAmount",
          toggle: true,
        },
      ],
      totalRecords: 0,
      loading: false,
      currentPage: 1,
      rowSelectionOptions: [10, 20, 50],
      rowsToShow: 10,
      sortBy: "-in_home_date",
    };
  },
  computed: {
    selectedColumns() {
      return this.allColumns.filter((item) => item.toggle);
    },
    campaign() {
      return this.appStore.currentCampaign;
    },
  },
  watch: {
    rowsToShow() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    formatMailings() {
      let mailings = [];

      for (let mailing of this.mailings) {
        let funnel_counts = mailing.funnel_counts;

        //Skip if no responses yet
        // if (!web_stats && !qr_stats) {
        //   continue;
        // }

        let newMailing = {
          id: mailing.id,
          name: mailing.name,
          description: mailing.description,
          contacts: numeral(mailing.total_mailed).format("0,0"),
          totalLeads:
            funnel_counts && funnel_counts.total_leads
              ? numeral(funnel_counts.total_leads).format("0,0")
              : 0,
          setCounter:
            funnel_counts && funnel_counts.set_counter
              ? numeral(funnel_counts.set_counter).format("0,0")
              : 0,
          issuedCounter:
            funnel_counts && funnel_counts.issued_counter
              ? numeral(funnel_counts.issued_counter).format("0,0")
              : 0,
          saleCounter:
            funnel_counts && funnel_counts.sale_counter
              ? numeral(funnel_counts.sale_counter).format("0,0")
              : 0,
          totalSalesAmount:
            funnel_counts && funnel_counts.total_sales_amount
              ? "$" + numeral(funnel_counts.total_sales_amount).format("0,0")
              : 0,
        };
        mailings.push(newMailing);
      }

      this.formattedMailings = mailings;
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },

    loadData() {
      this.loading = true;
      console.log(this.campaign);
      let qb = new QueryBuilderService(
        COMailing.where("campaign_id", this.campaign.id),
        {
          sort: this.sortBy,
          limit: this.rowsToShow,
          page: this.currentPage,
        }
      );

      qb.buildQuery()
        .get()
        .then((response) => {
          this.mailings = response.data;
          this.totalRecords = response.meta.total;
          this.formatMailings();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something Went Wrong. Please Try Again",
            life: 2000,
          });
        })
        .then(() => (this.loading = false));
    },
    onSort($event) {
      if (Object.prototype.hasOwnProperty.call($event, "sortOrder")) {
        if ($event.sortOrder === -1) {
          this.sortBy = "-" + this.sortBy;
        } else {
          this.sortBy = $event.sortField;
        }
        this.loadData();
      }
    },
    onPage(event) {
      this.currentPage = event.page + 1;
      this.loadData();
    },
  },
};
</script>

<style scoped></style>
