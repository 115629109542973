export default class QueryBuilderService {
  constructor(modelClass, options) {
    this.modelClass = modelClass;
    this.options = options;
  }

  buildQuery() {
    let model = this.modelClass;

    console.log(this.options);
    if (Object.prototype.hasOwnProperty.call(this.options, "limit")) {
      model = model.limit(this.options.limit);
    }
    if (Object.prototype.hasOwnProperty.call(this.options, "sort")) {
      model = model.orderBy(this.options.sort);
    }
    if (Object.prototype.hasOwnProperty.call(this.options, "page")) {
      model = model.page(this.options.page);
    }
    if (Object.prototype.hasOwnProperty.call(this.options, "filters")) {
      model = model.setFilters(this.options.filters);
    }
    return model;
  }
}
