<template>
  <TitleBar :actions="actions" :collapseActions="false">{{
    pageTitle
  }}</TitleBar>

  <div class="px-5 pb-5">
    <div v-if="isLoading">
      <div class="grid">
        <div
          class="col-12 lg:col-4"
          v-for="i in Array(3)"
          :key="'skeleton-' + i"
        >
          <div
            class="surface-card shadow-2 p-3 border-1 border-50 border-round"
          >
            <div class="flex justify-content-between mb-3">
              <Skeleton class="p-mb-2 h-7rem"></Skeleton>
            </div>
          </div>
        </div>
      </div>
      <div class="grid">
        <div
          class="col-12 lg:col-3"
          v-for="i in Array(4)"
          :key="'skeleton-' + i"
        >
          <div
            class="surface-card shadow-2 p-3 border-1 border-50 border-round"
          >
            <div class="flex justify-content-between mb-3">
              <Skeleton class="p-mb-2 h-12rem"></Skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="campaignOverview && !isLoading">
      <CampaignStats :campaign="campaignOverview" v-if="campaignOverview" />
    </div>

    <!-- <CampaignOverviewComponent
      :campaign="campaignOverview"
      v-if="campaignOverview && !isLoading"
    /> -->

    <MailingTable />
  </div>
</template>

<script>
import CampaignOverview from "@/models/CampaignOverview";
import CampaignStats from "@/components/CampaignStats";
// import CampaignOverviewComponent from "@/components/CampaignOverview";
import MailingTable from "@/components/MailingTable";
import TitleBar from "@/components/TitleBar";
import ChartLayout from "@/models/ChartLayout";
import Skeleton from "primevue/skeleton";
import { useAppStore } from "@/stores/app";
export default {
  components: {
    TitleBar,
    MailingTable,
    CampaignStats,

    // CampaignOverviewComponent,
    Skeleton,
  },
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  data() {
    return {
      campaignOverview: null,
      actions: [],
      isLoading: true,
      pageTitle: "",
    };
  },
  computed: {},
  async mounted() {
    this.pageTitle = this.appStore.currentCampaign.name + " Campaign Overview";

    if (process.env.VUE_APP_SHOW_REPORTING === "true") {
      this.actions.push({
        label: "Site Performance",
        link: "/reporting/site-performance",
      });
    }
    await ChartLayout.where("campaign_id", this.appStore.currentCampaign.id)
      .where("type", "campaign")
      .limit(1)
      .first()
      .then((data) => {
        if (data.charts) {
          this.actions.push({
            label: "Campaign Dashboard",
            link: "/campaign-dashboard",
          });
        }
      });

    await CampaignOverview.find(this.appStore.currentCampaign.id)
      .then((res) => {
        this.campaignOverview = res.data;
      })
      .catch((err) => {
        console.log(err);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something Went Wrong. Please Try Again",
          life: 2000,
        });
      });

    this.isLoading = false;
  },
};
</script>
